$selectedRowBackgroundColor: #23bebb35;
$unverifiedRowBackgroundColor: #fff1f0;
$yellowHighlightBackgroundColor: #ffe58f;
$redHighlightBackgroundColor: #ffccc7;

.hidden { display:none; }

.selectedRow {
    background-color: $selectedRowBackgroundColor !important;
    cursor: pointer;
  
    &:hover {
      background-color: $selectedRowBackgroundColor !important;
    }
}

.unverifiedRow {
  background-color: $unverifiedRowBackgroundColor !important;

  &:hover {
    background-color: $unverifiedRowBackgroundColor !important;
  }
}

.expiringSoonRow {
  cursor: pointer;
  background: $yellowHighlightBackgroundColor !important;

  &:hover {
    background: $yellowHighlightBackgroundColor !important;
  }
}

.expiredRow {
  cursor: pointer;
  background: $redHighlightBackgroundColor !important;

  &:hover {
    background: $redHighlightBackgroundColor !important;
  }
}

.unselectedRow {
  cursor: pointer;
}

.dataTableRow {
  cursor: pointer;
}

.columnNoBreak {
  white-space: nowrap;
}
  
.selectedRow a, .selectedRow a:hover {
  text-decoration: underline;
}
  
.unselectedRow a, .unselectedRow a:hover {
  text-decoration: underline;
}

:global .ant-table-body {
  overflow-x: auto;
}

// :global .ant-drawer-body {
//   padding: 12px;
// }