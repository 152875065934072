img { max-width:100%; }

.timeDate {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.sentMessage p {
    background: #05728f none repeat scroll 0 0;
    border-radius: 3px;
    font-size: 14px;
    margin: 0; 
    color:#fff;
    padding: 5px 10px 5px 12px;
    width: 100%;
}

.outgoingMessage { 
    overflow:hidden; 
    margin:26px 0 26px;
}

.sentMessage {
    float: right;
    width: 46%;
}
.unreadDot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin-left: 5px;
  margin-top: 4px;
  background: firebrick;
}