@import "../../theme/forms.scss";

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #ececec;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.loadSummaryContainer {
  // min-height: calc(100vh - 8px);
  // max-height: calc(100vh - 8px);
  height: calc(100vh - 8px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.loadSummary {
  flex: auto;
  overflow-y: auto;
}

.loadSummaryButtonContainer {
  //position: relative;
  display: inline-flex;
  //right: 0;
  width: 100%;
  border-top: 4px solid #e9e9e9;
  padding: 0;
  margin: 0 !important;
  background: #ffffff;
  // align-items: center;
  // justify-content: space-evenly;
  // height: 60px;
  height: fit-content;
}

:global .load-details-title {
  font-size: 14px;
  color: #A9A9A9;
}

:global .load-details-data {
  font-size: 16px;
  color: #000000;
}

:global .load-summary-big-title {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

:global .load-summary-title {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

:global .load-summary-subtitle {
  font-size: 14px;
  color: #000000;
}

:global .load-summary-subtitle-italics {
  font-size: 14px;
  color: #000000;
  font-style: italic;
}

:global .load-summary-data {
  font-size: 14px;
  color: #A9A9A9;
}

:global .load-creation-step-container {
  min-height: calc(100vh - 144px);
}

:global .load-creation-step {
  background-color: #ffffff;
  padding: 16px 24px;
}

:global .load-creation-step-buttons {
  //position: relative;
  display: inline-flex;
  //right: 0;
  width: 100%;
  border-top: 4px solid #e9e9e9;
  padding: 0;
  margin: 0 !important;
  background: #ffffff;
  // align-items: center;
  // justify-content: space-evenly;
  // height: 60px;
  height: fit-content;
}