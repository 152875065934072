.hidden {
  display: none;
}

.selectedRow {
  background-color: rgba(35, 190, 187, 0.2078431373) !important;
  cursor: pointer;
}
.selectedRow:hover {
  background-color: rgba(35, 190, 187, 0.2078431373) !important;
}

.unverifiedRow {
  background-color: #fff1f0 !important;
}
.unverifiedRow:hover {
  background-color: #fff1f0 !important;
}

.expiringSoonRow {
  cursor: pointer;
  background: #ffe58f !important;
}
.expiringSoonRow:hover {
  background: #ffe58f !important;
}

.expiredRow {
  cursor: pointer;
  background: #ffccc7 !important;
}
.expiredRow:hover {
  background: #ffccc7 !important;
}

.unselectedRow {
  cursor: pointer;
}

.dataTableRow {
  cursor: pointer;
}

.columnNoBreak {
  white-space: nowrap;
}

.selectedRow a, .selectedRow a:hover {
  text-decoration: underline;
}

.unselectedRow a, .unselectedRow a:hover {
  text-decoration: underline;
}

:global .ant-table-body {
  overflow-x: auto;
}