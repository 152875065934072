.button {
  height: 60px;
  width: 100%;
  border-radius: 2px;
  padding: 0;
  display: flex;
}

.buttonGroup {
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
}
.buttonGroup .buttonGroupButton {
  height: 60px;
  min-width: 0;
  display: flex;
  padding: 0;
}
.buttonGroup .buttonGroupButton:first-child:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.buttonGroup .buttonGroupButton:last-child:not(:first-child) {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.iconContainer {
  height: 100%;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
}

.textContainer {
  height: 100%;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  display: inline-flex;
  text-align: left;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
  margin-left: 16px;
}

.button1icon1 {
  width: 24px !important;
  height: 24px !important;
  margin-left: 16px;
}

.button1icon2 {
  width: 24px !important;
  height: 24px !important;
  margin-right: 16px;
}

.button2icon1 {
  width: 24px !important;
  height: 24px !important;
  margin-right: 16px;
  margin-left: 16px;
}

.yellowButton {
  background-color: #FFFBE5;
  background: #FFFBE5;
  color: #000000;
  border: 1px solid #BFBFBF;
}
.yellowButton:hover, .yellowButton:active, .yellowButton:visited, .yellowButton:link, .yellowButton:focus, .yellowButton[disabled], .yellowButton[disabled]:hover, .yellowButton[disabled]:active, .yellowButton[disabled]:visited, .yellowButton[disabled]:link, .yellowButton[disabled]:focus {
  background-color: #FFFBE5;
  background: #FFFBE5;
  color: #000000;
  border: 1px solid #BFBFBF;
}
.yellowButton .iconContainer {
  background-color: #FFFBE5;
  background: #FFFBE5;
  color: #000000;
}
.yellowButton .textContainer {
  background-color: #FFFBE5;
  background: #FFFBE5;
  color: #000000;
}
.yellowButton .button1icon1 {
  color: #000000;
}
.yellowButton .button1icon2 {
  color: #000000;
}
.yellowButton .button2icon1 {
  color: #000000;
}

.redButton {
  background-color: #FFE4E2;
  background: #FFE4E2;
  color: #000000;
  border: 1px solid #BFBFBF;
}
.redButton:hover, .redButton:active, .redButton:visited, .redButton:link, .redButton:focus, .redButton[disabled], .redButton[disabled]:hover, .redButton[disabled]:active, .redButton[disabled]:visited, .redButton[disabled]:link, .redButton[disabled]:focus {
  background-color: #FFE4E2;
  background: #FFE4E2;
  color: #000000;
  border: 1px solid #BFBFBF;
}
.redButton .iconContainer {
  background-color: #FFE4E2;
  background: #FFE4E2;
  color: #000000;
}
.redButton .textContainer {
  background-color: #FFE4E2;
  background: #FFE4E2;
  color: #000000;
}
.redButton .button1icon1 {
  color: #000000;
}
.redButton .button1icon2 {
  color: #000000;
}
.redButton .button2icon1 {
  color: #000000;
}

.blueButton {
  background-color: #16588E;
  background: #16588E;
  color: #FFFFFF;
  border: 1px solid #16588E;
}
.blueButton:hover, .blueButton:active, .blueButton:visited, .blueButton:link, .blueButton:focus, .blueButton[disabled], .blueButton[disabled]:hover, .blueButton[disabled]:active, .blueButton[disabled]:visited, .blueButton[disabled]:link, .blueButton[disabled]:focus {
  background-color: #16588E;
  background: #16588E;
  color: #FFFFFF;
  border: 1px solid #16588E;
}
.blueButton .iconContainer {
  background-color: #16588E;
  background: #16588E;
  color: #FFFFFF;
}
.blueButton .textContainer {
  background-color: #16588E;
  background: #16588E;
  color: #FFFFFF;
}
.blueButton .button1icon1 {
  color: #FFFFFF;
}
.blueButton .button1icon2 {
  color: #FFFFFF;
}
.blueButton .button2icon1 {
  color: #FFFFFF;
}

.whiteButtonSolidBorder {
  background-color: #FFFFFF;
  background: #FFFFFF;
  color: #000000;
  border: 1px solid #979797;
}
.whiteButtonSolidBorder:hover, .whiteButtonSolidBorder:active, .whiteButtonSolidBorder:visited, .whiteButtonSolidBorder:link, .whiteButtonSolidBorder:focus, .whiteButtonSolidBorder[disabled], .whiteButtonSolidBorder[disabled]:hover, .whiteButtonSolidBorder[disabled]:active, .whiteButtonSolidBorder[disabled]:visited, .whiteButtonSolidBorder[disabled]:link, .whiteButtonSolidBorder[disabled]:focus {
  background-color: #FFFFFF;
  background: #FFFFFF;
  color: #000000;
  border: 1px solid #979797;
}
.whiteButtonSolidBorder .iconContainer {
  background-color: #FFFFFF;
  background: #FFFFFF;
  color: #000000;
}
.whiteButtonSolidBorder .textContainer {
  background-color: #FFFFFF;
  background: #FFFFFF;
  color: #000000;
}
.whiteButtonSolidBorder .button1icon1 {
  color: #000000;
}
.whiteButtonSolidBorder .button1icon2 {
  color: #000000;
}
.whiteButtonSolidBorder .button2icon1 {
  color: #000000;
}

.whiteButtonDashedBorder {
  background-color: #FFFFFF;
  background: #FFFFFF;
  color: #000000;
  border: 1px dashed #979797;
}
.whiteButtonDashedBorder:hover, .whiteButtonDashedBorder:active, .whiteButtonDashedBorder:visited, .whiteButtonDashedBorder:link, .whiteButtonDashedBorder:focus, .whiteButtonDashedBorder[disabled], .whiteButtonDashedBorder[disabled]:hover, .whiteButtonDashedBorder[disabled]:active, .whiteButtonDashedBorder[disabled]:visited, .whiteButtonDashedBorder[disabled]:link, .whiteButtonDashedBorder[disabled]:focus {
  background-color: #FFFFFF;
  background: #FFFFFF;
  color: #000000;
  border: 1px dashed #979797;
}
.whiteButtonDashedBorder .iconContainer {
  background-color: #FFFFFF;
  background: #FFFFFF;
  color: #000000;
}
.whiteButtonDashedBorder .textContainer {
  background-color: #FFFFFF;
  background: #FFFFFF;
  color: #000000;
}
.whiteButtonDashedBorder .button1icon1 {
  color: #000000;
}
.whiteButtonDashedBorder .button1icon2 {
  color: #000000;
}
.whiteButtonDashedBorder .button2icon1 {
  color: #000000;
}

.grayButtonSolidBorder {
  background-color: #F5F5F5;
  background: #F5F5F5;
  color: #979797;
  border: 1px solid #979797;
}
.grayButtonSolidBorder:hover, .grayButtonSolidBorder:active, .grayButtonSolidBorder:visited, .grayButtonSolidBorder:link, .grayButtonSolidBorder:focus, .grayButtonSolidBorder[disabled], .grayButtonSolidBorder[disabled]:hover, .grayButtonSolidBorder[disabled]:active, .grayButtonSolidBorder[disabled]:visited, .grayButtonSolidBorder[disabled]:link, .grayButtonSolidBorder[disabled]:focus {
  background-color: #F5F5F5;
  background: #F5F5F5;
  color: #979797;
  border: 1px solid #979797;
}
.grayButtonSolidBorder .iconContainer {
  background-color: #F5F5F5;
  background: #F5F5F5;
  color: #979797;
}
.grayButtonSolidBorder .textContainer {
  background-color: #F5F5F5;
  background: #F5F5F5;
  color: #979797;
}
.grayButtonSolidBorder .button1icon1 {
  color: #000000;
}
.grayButtonSolidBorder .button1icon2 {
  color: #000000;
}
.grayButtonSolidBorder .button2icon1 {
  color: #000000;
}

.grayButtonDashedBorder {
  background-color: #F5F5F5;
  background: #F5F5F5;
  color: #979797;
  border: 1px dashed #979797;
}
.grayButtonDashedBorder:hover, .grayButtonDashedBorder:active, .grayButtonDashedBorder:visited, .grayButtonDashedBorder:link, .grayButtonDashedBorder:focus, .grayButtonDashedBorder[disabled], .grayButtonDashedBorder[disabled]:hover, .grayButtonDashedBorder[disabled]:active, .grayButtonDashedBorder[disabled]:visited, .grayButtonDashedBorder[disabled]:link, .grayButtonDashedBorder[disabled]:focus {
  background-color: #F5F5F5;
  background: #F5F5F5;
  color: #979797;
  border: 1px dashed #979797;
}
.grayButtonDashedBorder .iconContainer {
  background-color: #F5F5F5;
  background: #F5F5F5;
  color: #979797;
}
.grayButtonDashedBorder .textContainer {
  background-color: #F5F5F5;
  background: #F5F5F5;
  color: #979797;
}
.grayButtonDashedBorder .button1icon1 {
  color: #000000;
}
.grayButtonDashedBorder .button1icon2 {
  color: #000000;
}
.grayButtonDashedBorder .button2icon1 {
  color: #000000;
}