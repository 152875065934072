.title {
    display: block;
    float: left;
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 10px;
    color: rgba(255, 255, 255, 1.0);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.invoiceHeaderTitle {
    padding: 2px 0;
    background: #333333;
    //border: 2px solid #000;
    color: #ffffff;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
}
.invoiceHeaderData {
    padding: 2px 0;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
}

.pageHeader {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 16px 0 16px 0;
    padding: 4px;
    background-color: #333333;
    color: rgba(255, 255, 255, 1.0);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
    font-feature-settings: 'tnum', "tnum";
    position: relative;
}

.heading {
    width: 100%;
    overflow: hidden;
}

.buttonContainer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #ffffff;
    text-align: right;
}