$blueBackground: #16588E;
$whiteBackground: #FFFFFF;
$grayBackground: #F5F5F5;
$yellowBackground: #FFFBE5;
$redBackground: #FFE4E2;
$irisGreen: #16588E;
$grayText: #979797;
$blackText: #000000;
$whiteText: #FFFFFF;
$grayBorder: #BFBFBF;

.button {
    height: 60px;
    width: 100%;
    border-radius: 2px;
    padding: 0;
    display: flex;
}

.buttonGroup {
    position: relative;
    height: 60px;
    width: 100%;
    display: flex;

    .buttonGroupButton {
        height: 60px;
        min-width: 0;
        display: flex;
        padding: 0;

        &:first-child:not(:last-child) {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }

        &:last-child:not(:first-child) {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }
    }
}

.iconContainer {
    height: 100%;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
}

.textContainer {
    height: 100%;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    display: inline-flex;
    text-align: left;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 16px;
    margin-left: 16px;
}

.button1icon1 {
    width: 24px !important;
    height: 24px !important;
    margin-left: 16px;
}

.button1icon2 {
    width: 24px !important;
    height: 24px !important;
    margin-right: 16px;
}

.button2icon1 {
    width: 24px !important;
    height: 24px !important;
    margin-right: 16px;
    margin-left: 16px;
}

.yellowButton {
    background-color: $yellowBackground;
    background: $yellowBackground;
    color: $blackText;
    border: 1px solid $grayBorder;

    &:hover, &:active, &:visited, &:link, &:focus, &[disabled], &[disabled]:hover, &[disabled]:active, &[disabled]:visited, &[disabled]:link, &[disabled]:focus {
        background-color: $yellowBackground;
        background: $yellowBackground;
        color: $blackText;
        border: 1px solid $grayBorder;
    }

    .iconContainer {
        background-color: $yellowBackground;
        background: $yellowBackground;
        color: $blackText;
    }

    .textContainer {
        background-color: $yellowBackground;
        background: $yellowBackground;
        color: $blackText;
    }

    .button1icon1 {
        color: $blackText;
    }

    .button1icon2 {
        color: $blackText;
    }

    .button2icon1 {
        color: $blackText;
    }
}

.redButton {
    background-color: $redBackground;
    background: $redBackground;
    color: $blackText;
    border: 1px solid $grayBorder;

    &:hover, &:active, &:visited, &:link, &:focus, &[disabled], &[disabled]:hover, &[disabled]:active, &[disabled]:visited, &[disabled]:link, &[disabled]:focus {
        background-color: $redBackground;
        background: $redBackground;
        color: $blackText;
        border: 1px solid $grayBorder;
    }

    .iconContainer {
        background-color: $redBackground;
        background: $redBackground;
        color: $blackText;
    }

    .textContainer {
        background-color: $redBackground;
        background: $redBackground;
        color: $blackText;
    }

    .button1icon1 {
        color: $blackText;
    }

    .button1icon2 {
        color: $blackText;
    }

    .button2icon1 {
        color: $blackText;
    }
}

.blueButton {
    background-color: $blueBackground;
    background: $blueBackground;
    color: $whiteText;
    border: 1px solid $irisGreen;

    &:hover, &:active, &:visited, &:link, &:focus, &[disabled], &[disabled]:hover, &[disabled]:active, &[disabled]:visited, &[disabled]:link, &[disabled]:focus {
        background-color: $blueBackground;
        background: $blueBackground;
        color: $whiteText;
        border: 1px solid $irisGreen;
    }

    .iconContainer {
        background-color: $blueBackground;
        background: $blueBackground;
        color: $whiteText;
    }

    .textContainer {
        background-color: $blueBackground;
        background: $blueBackground;
        color: $whiteText;
    }

    .button1icon1 {
        color: $whiteText;
    }

    .button1icon2 {
        color: $whiteText;
    }

    .button2icon1 {
        color: $whiteText;
    }
}

.whiteButtonSolidBorder {
    background-color: $whiteBackground;
    background: $whiteBackground;
    color: $blackText;
    border: 1px solid $grayText;

    &:hover, &:active, &:visited, &:link, &:focus, &[disabled], &[disabled]:hover, &[disabled]:active, &[disabled]:visited, &[disabled]:link, &[disabled]:focus {
        background-color: $whiteBackground;
        background: $whiteBackground;
        color: $blackText;
        border: 1px solid $grayText;
    }

    .iconContainer {
        background-color: $whiteBackground;
        background: $whiteBackground;
        color: $blackText;
    }

    .textContainer {
        background-color: $whiteBackground;
        background: $whiteBackground;
        color: $blackText;
    }
    
    .button1icon1 {
        color: $blackText;
    }

    .button1icon2 {
        color: $blackText;
    }

    .button2icon1 {
        color: $blackText;
    }
}

.whiteButtonDashedBorder {
    background-color: $whiteBackground;
    background: $whiteBackground;
    color: $blackText;
    border: 1px dashed $grayText;

    &:hover, &:active, &:visited, &:link, &:focus, &[disabled], &[disabled]:hover, &[disabled]:active, &[disabled]:visited, &[disabled]:link, &[disabled]:focus {
        background-color: $whiteBackground;
        background: $whiteBackground;
        color: $blackText;
        border: 1px dashed $grayText;
    }

    .iconContainer {
        background-color: $whiteBackground;
        background: $whiteBackground;
        color: $blackText;
    }

    .textContainer {
        background-color: $whiteBackground;
        background: $whiteBackground;
        color: $blackText;
    }
    
    .button1icon1 {
        color: $blackText;
    }

    .button1icon2 {
        color: $blackText;
    }

    .button2icon1 {
        color: $blackText;
    }
}

.grayButtonSolidBorder {
    background-color: $grayBackground;
    background: $grayBackground;
    color: $grayText;
    border: 1px solid $grayText;

    &:hover, &:active, &:visited, &:link, &:focus, &[disabled], &[disabled]:hover, &[disabled]:active, &[disabled]:visited, &[disabled]:link, &[disabled]:focus {
        background-color: $grayBackground;
        background: $grayBackground;
        color: $grayText;
        border: 1px solid $grayText;
    }

    .iconContainer {
        background-color: $grayBackground;
        background: $grayBackground;
        color: $grayText;
    }

    .textContainer {
        background-color: $grayBackground;
        background: $grayBackground;
        color: $grayText;
    }
    
    .button1icon1 {
        color: $blackText;
    }

    .button1icon2 {
        color: $blackText;
    }

    .button2icon1 {
        color: $blackText;
    }
}

.grayButtonDashedBorder {
    background-color: $grayBackground;
    background: $grayBackground;
    color: $grayText;
    border: 1px dashed $grayText;

    &:hover, &:active, &:visited, &:link, &:focus, &[disabled], &[disabled]:hover, &[disabled]:active, &[disabled]:visited, &[disabled]:link, &[disabled]:focus {
        background-color: $grayBackground;
        background: $grayBackground;
        color: $grayText;
        border: 1px dashed $grayText;
    }

    .iconContainer {
        background-color: $grayBackground;
        background: $grayBackground;
        color: $grayText;
    }

    .textContainer {
        background-color: $grayBackground;
        background: $grayBackground;
        color: $grayText;
    }
    
    .button1icon1 {
        color: $blackText;
    }

    .button1icon2 {
        color: $blackText;
    }

    .button2icon1 {
        color: $blackText;
    }
}
