.item {
    padding-right: 0px;
    padding-left: 0px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;

    .meta {
        width: 100%;
    }

    .avatar {
        margin-top: 4px;
        background: #fff;
    }

    .iconElement {
        font-size: 32px;
    }

    &.read {
        opacity: 0.5;
    }

    &:last-child {
        border-bottom: 0;
    }

    // &:hover {
    //     background: #7c7c7c;
    // }

    .title {
        margin-bottom: 8px;
        font-weight: normal;
    }

    .description {
        font-size: 12px;
        line-height: 1.5;
    }

    .dateTime {
        margin-top: 4px;
        font-size: 12px;
        line-height: 1.5;
    }

    .extra {
        float: right;
        margin-top: -1.5px;
        margin-right: 0;
        color: #000000;
        font-weight: normal;
    }
}

.bottomBar {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #16588E;
    color: #ffffff;
    text-align: center;

    div {
        display: inline-block;
        width: 50%;
        cursor: pointer;
        transition: all 0.3s;
        user-select: none;
        text-align: center;
    
        &:only-child {
          width: 100%;
        }

        &:not(:only-child):last-child {
          border-left: 1px solid #e9e9e9;
        }
    }
}

.infiniteScrollContainer {
    overflow: scroll;
    height: 100vh
}
