.card {
    min-height: 400px;
}

.ml2 {
    margin-left: 12px;
    display: inline-block;
}
@media (max-width: 576px) {
    .card {
        overflow: visible;
        min-width: 200px;
    }
}