$whiteBackground: #ffffff;
$irisGreen: #16588E;
$blackText: #000000;

.iconContainer {
    height: 100%;
    width: 60px;
    line-height: 40px;
    display: inline-flex;
    align-items: center;
}

.textContainer {
    height: 100%;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    display: inline-flex;
    text-align: left;
    flex: 1;
}

.icon {
    width: 40px !important;
    height: 40px !important;
    margin-right: 12px;
}

.icon2 {
    width: 40px !important;
    height: 40px !important;
    margin-left: 12px;
}

.actionButton {
    background-color: $whiteBackground;
    background: $whiteBackground;
    color: $blackText;
    height: 60px;
    width: 100%;
    border-radius: 15px;
    padding: 4px 8px;
    border: 1px solid $irisGreen;
    display: flex;

    &:hover, &:active, &:visited, &:link, &:focus, &[disabled], &[disabled]:hover, &[disabled]:active, &[disabled]:visited, &[disabled]:link, &[disabled]:focus {
        background-color: $whiteBackground;
        background: $whiteBackground;
        color: $blackText;
        border: 1px solid $irisGreen;
    }

    .iconContainer {
        background-color: $whiteBackground;
        background: $whiteBackground;
        color: $blackText;
    }

    .textContainer {
        background-color: $whiteBackground;
        background: $whiteBackground;
        color: $blackText;
    }

    .icon {
        color: $irisGreen;
        font-size: 40px;
    }

    .icon2 {
        color: $irisGreen;
        font-size: 40px;
    }
}
