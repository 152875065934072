div {
    break-inside: avoid-page;
    -webkit-break-inside: avoid-page;
}

@media print {
    :global .ant-row { break-inside: avoid-page; }
}

.title {
    display: block;
    float: left;
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 10px;
    color: rgba(255, 255, 255, 1.0);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.titleBlock {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 16px 0 16px 0;
    padding: 4px 10px 4px 10px;
    background-color: #333333;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    display: block;
}

.pageHeader {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 16px 0 16px 0;
    padding: 4px;
    background-color: #333333;
    color: rgba(255, 255, 255, 1.0);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
    font-feature-settings: 'tnum', "tnum";
    position: relative;
}

.heading {
    width: 100%;
    overflow: hidden;
}