@import "../../theme/dispatchButtons.scss";

$blueBackground: #334353;
$whiteBackground: #FFFFFF;
$greenBackground: #F6FFED;
$redBackground: #FFE4E2;
$yellowBackground: #FFFBE6;
$grayBackground: #E9E9E9;
$irisGreen: #16588E;
$grayText: #979797;
$blackText: #000000;
$whiteText: #FFFFFF;
$grayBorder: #BFBFBF;

$dispatchedBackground: $greenBackground;
$undispatchedBackground: $whiteBackground;
$missingBackground: $redBackground;
$readyToDispatchBackground: $blueBackground;
$selectedBackground: $yellowBackground;
$hoverBackground: #fafafa;

$selectedCardBackgroundColor: #334353;
$selectedTextColor: #ffffff;
$selectedBorderColor: #16588E;
$unselectedCardBackgroundColor: #ffffff;
$unselectedTextColor: #000000;
$unselectedBorderColor: #E9E9E9;
$yellowHighlightBackgroundColor: #ffe58f;
$redHighlightBackgroundColor: #ffccc7;

.block {
    display: block;
}
.item {
    padding-right: 0px;
    padding-left: 0px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;

    .meta {
        width: 100%;
    }

    .avatar {
        margin-top: 4px;
        background: #fff;
    }

    .iconElement {
        font-size: 32px;
    }

    &.read {
        opacity: 0.5;
    }

    &:last-child {
        border-bottom: 0;
    }

    // &:hover {
    //     background: #7c7c7c;
    // }

    .title {
        margin-bottom: 8px;
        font-weight: normal;
    }

    .description {
        font-size: 12px;
        line-height: 1.5;
    }

    .dateTime {
        margin-top: 4px;
        font-size: 12px;
        line-height: 1.5;
    }

    .extra {
        float: right;
        margin-top: -1.5px;
        margin-right: 0;
        color: #000000;
        font-weight: normal;
    }
}

.bottomBar {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #16588E;
    color: #ffffff;
    text-align: center;

    div {
        display: inline-block;
        width: 50%;
        cursor: pointer;
        transition: all 0.3s;
        user-select: none;
        text-align: center;

        &:only-child {
            width: 100%;
        }

        &:not(:only-child):last-child {
            border-left: 1px solid #e9e9e9;
        }
    }
}

.infiniteScrollContainer {
    overflow: auto;
    height: 80vh;
}

.emptyList {
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    flex-direction: column;
    display: inline-flex;
    width: 100%;
    height: 300px;

    .emptyIconContainer {
        color: $unselectedTextColor;
        font-size: 60px;
    }

    .emptyTextContainer {
        color: $unselectedTextColor;
        font-size: 24px;
        font-weight: bold;
    }
}

.separator {
    height: 0.5;
    background-color: rgba(0,0,0,0.4);
}

:global .loadsListTimeline {
    .ant-timeline-item-last > .ant-timeline-item-content {
        min-height: 0;
    }
}

:global .loadsListTimelineItem {
    padding: 0px;
    font-size: 10px;
    line-height: 1.2;

    .ant-timeline-item-tail {
        position: absolute;
        top: 10px;
        left: 2px;
        height: calc(100% - 15px);
        border-left: 5px dotted #f0f0f0;
    }

    .ant-timeline-item-head {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 100px;
    }
}

.cardItemContainer {
    //display: table-cell;
    //flex: 1;
    margin-right: 24px;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    width: 300px;
    height: 200px;
    // &:nth-of-type(2) {
    //     margin-left: 60px;
    // }
    // &:nth-last-of-type(2) {
    //     margin-right: 60px;
    // }
}

.cardItemContainerCollapsed {
    height: 90px;
}

.cardItemInfiniteScrollContainer {
    overflow: scroll hidden;
    display: flex;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 38px;
    margin-right: 38px;
    margin-bottom: 10px;
    position: relative;
}

.buttonContainer {
    position: relative;
    width: 30px;
    height: 0;
    left: 0;
    top: 0;
    z-index: 500;
}

.leftButtonContainer {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}

.rightButtonContainer {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}

.hasListActions {
    .leftButtonContainer {
        top: 107px;
    }

    .rightButtonContainer {
        top: 107px;
    }

    .cardItemInfiniteScrollContainer {
        padding-top: 45px
    }
}

.scrollButton {
    align-self: center;
    justify-self: center;
    flex: 1;
    height: 30px;
    width: 30px;
    opacity: 0.5;
}

.hidden { display:none; }

.loadDetails {
    display: flex;
    margin-top: 12px;

    .loadDate {
        width: 40px;
        margin-right: 12px;
    }

    .loadRoute {
        flex: 1;
    }
}

:global .block {
    display: block;
}
:global .selectedLoadsListCard {
    width: 300px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    background-color: $selectedCardBackgroundColor !important;
    color: $selectedTextColor !important;
    border: 3px solid $selectedBorderColor;
    cursor: pointer;

    .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: $selectedTextColor !important;
    }

    .subtitle {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: $selectedTextColor !important;

        a {
            color: $selectedTextColor !important;

            &:hover {
                color: $selectedTextColor !important;
            }
        }
    }

    .stopDetails {
        margin-bottom: 12px;

        .locationDetails {
            font-size: 14px;
            line-height: 22px;
        }

        .apptDetails {
            font-size: 10px;
            line-height: 22px;
            opacity: 0.65;
        }
    }

    .stopDetails:last-of-type {
        margin-bottom: 0px;
    }

    .loadsListTimelineItem {
        color: $selectedTextColor !important;
    }

    .ant-card-actions {
        margin: 0;
        padding: 0;
        list-style: none;
        background: transparent;
        border-top: 1px solid #f0f0f0;
        position: absolute;
        height: 60px;
        width: 100%;
        bottom: 0;
        left: 0;

        li {
            color: $selectedTextColor !important;

            span {
                color: $selectedTextColor !important;

                &:hover {
                    color: $selectedTextColor !important;
                }

                .ant-btn {
                    span {
                        color: $unselectedTextColor !important;
                    }
                }
            }
        }
    }

    &:hover {
        background-color: $selectedCardBackgroundColor !important;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}

:global .responsive-table {
    li {
        border-radius: 3px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .table-header {
        background-color: #95A5A6;
        font-size: 14px;
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 0.03em;
    }
    .table-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    }
    .col-1 {
        flex-basis: 10%;
    }
    .col-2 {
        flex-basis: 40%;
    }
    .col-3 {
        flex-basis: 25%;
    }
    .col-4 {
        flex-basis: 25%;
    }

    @media all and (max-width: 767px) {
        .table-header {
            display: none;
        }
        .table-row{

        }
        li {
            display: block;
        }
        .col {

            flex-basis: 100%;

        }
        .col {
            display: flex;
            padding: 10px 0;
            &:before {
                color: #6C7A89;
                padding-right: 10px;
                content: attr(data-label);
                flex-basis: 50%;
                text-align: right;
            }
        }
    }
}

:global .unselectedLoadsListCard {
    width: 300px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    background-color: $unselectedCardBackgroundColor !important;
    color: $unselectedTextColor !important;
    border: 3px solid $unselectedBorderColor;
    cursor: pointer;

    .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: $unselectedTextColor !important;
        opacity: 0.85;
    }

    .subtitle {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: $unselectedTextColor !important;
        opacity: 0.65;


        a {
            color: $unselectedTextColor !important;
            opacity: 0.65;

            &:hover {
                color: $unselectedTextColor !important;
                opacity: 0.65;
            }
        }
    }

    .stopDetails {
        margin-bottom: 12px;

        .locationDetails {
            font-size: 14px;
            line-height: 22px;
        }

        .apptDetails {
            font-size: 10px;
            line-height: 22px;
        }
    }

    .stopDetails:last-of-type {
        margin-bottom: 0px;
    }

    .loadsListTimelineItem {
        color: $unselectedTextColor !important;
    }

    .ant-card-actions {
        margin: 0;
        padding: 0;
        list-style: none;
        background: transparent;
        border-top: 1px solid #f0f0f0;
        position: absolute;
        height: 60px;
        width: 100%;
        bottom: 0;
        left: 0;

        li {
            color: $unselectedTextColor !important;

            span {
                color: $unselectedTextColor !important;

                &:hover {
                    color: $unselectedTextColor !important;
                }

                .ant-btn {
                    span {
                        color: $unselectedTextColor !important;
                    }
                }
            }
        }
    }

    &:hover {
        background-color: $unselectedCardBackgroundColor !important;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }
}

:global .collapsedListCard {
    height: 90px;
}

.columnNoBreak {
    white-space: nowrap;
}

.calendarDate {
    width: 40px;

    .monthContainer {
        width: 40px;
        text-align: center;
        margin-bottom: 5px;

        span {
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .calendarContainer {
        position: absolute;
        align-items: center;
        justify-content: center;

        .calendarIcon {
            width: 40px;
            height: 40px;
        }
    }

    .dayContainer {
        position: relative;
        align-items: center;
        justify-content: center;
        width: 40px;
        line-height: 40px;
        height: 40px;
        top: 4px;
        text-align: center;

        span {
            font-weight: bold;
        }
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.icon {
    width: 40px !important;
    height: 40px !important;
    margin-right: 12px;
    color: inherit;
}

.cell {
    text-align: center;
    white-space: nowrap;

    div {
        white-space: nowrap;
        text-align: center;
    }
}

.dispatchedRow {
    background-color: $dispatchedBackground !important;
    background: $dispatchedBackground !important;

    &:hover {
        background-color: $dispatchedBackground !important;
        background: $dispatchedBackground !important;
    }
}

.selectedRow {
    background-color: $selectedBackground !important;
    background: $selectedBackground !important;

    &:hover {
        background-color: $selectedBackground !important;
        background: $selectedBackground !important;
    }
}

.missingRow {
    background-color: $missingBackground !important;
    background: $missingBackground !important;

    &:hover {
        background-color: $missingBackground !important;
        background: $missingBackground !important;
    }
}

.readyToDispatchRow {
    background-color: $readyToDispatchBackground !important;
    background: $readyToDispatchBackground !important;
    color: $whiteText !important;

    a {
        color: $whiteText !important;
    }

    div {
        button {
            color: $whiteText !important;

            &:hover {
                color: $whiteText !important;
            }
        }
    }

    &:hover {
        background-color: $readyToDispatchBackground !important;
        background: $readyToDispatchBackground !important;
        color: $whiteText !important;

        a {
            color: $whiteText !important;
        }

        div {
            button {
                color: $whiteText !important;

                &:hover {
                    color: $whiteText !important;
                }
            }
        }
    }
}

.undispatchedRow {
    // background-color: transparent !important;
    // background: transparent !important;

    &:hover {
        background-color: $hoverBackground !important;
        background: $hoverBackground !important;
    }
}

.stopDetails {
    display: block;
    margin-bottom: 40px;

    .stopLocationContainer {
        display: flex;
        margin-bottom: 12px;

        .stopIconContainer {
            width: 30px;
            margin-right: 12px;

            .stopIcon {
                width: 30px;
                height: 30px;
            }
        }

        .stopLocation {
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            color: $blackText;
            flex: 1;
            text-align: left;
        }
    }

    .stopAddress {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $blackText;
        opacity: 0.65;
    }

    .apptDetails {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: $blackText;
        opacity: 0.45;
    }
}
